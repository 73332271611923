<template>
  <div v-if="eventData">
    <b-card header="Event Details">
      <div class="mb-1">
        <b>Name: </b> {{ eventData.activity_name }}
      </div>
      <div class="mb-1">
        <b>Start Datetime: </b> {{ eventData.start_time }}
      </div>
      <div class="mb-1">
        <b>End Datetime: </b> {{ eventData.end_time }}
      </div>
      <div class="mb-1">
        <b>Presence: </b> {{ eventData.presence_type }}
      </div>
      <div class="mb-1">
        <b>Notes: </b> {{ eventData.activity_description }}
      </div>
    </b-card>

    <b-card>
      <b-table
        ref="table"
        :no-provider-sorting="true"
        show-empty
        empty-text="There is no Sessions for this Event!"
        :items="sessionsList"
        :fields="sessionsTableColumns"
        aria-busy="true"
        class="position-relative"
        responsive
        primary-key="id"
      >
        <template #cell(session_approval)="{item}">
          {{ item.session_approval ? 'Yes' : 'No' }}
        </template>
        <template #cell(required_vaccination)="{item}">
          <b-form-checkbox
            v-model="item.required_vaccination"
            type="checkbox"
            :value="1"
            :unchecked-value="0"
            disabled
          />
        </template>
        <template #cell(session_description)="{item}">
          {{ item.session_description ? item.session_description.slice(0,50) : '' }}
        </template>
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'sessions-view', params: { id: item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View Bookings</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import getEventWithSessions from '@/common/compositions/PrayerBookings/getEventWithSessions'

export default {
  name: 'ViewEvent',
  data() {
    return {
      sessionsTableColumns: [
        { key: 'topic' },
        { key: 'session_provider', label: 'Speaker' },
        { key: 'session_start', label: 'Start' },
        { key: 'session_end', label: 'End' },
        { key: 'action_type' },
        { key: 'session_languages', label: 'Languages' },
        { key: 'session_status', label: 'Status' },
        { key: 'booking_details_count', label: 'Bookings Count' },
        { key: 'session_approval', label: 'Approval' },
        { key: 'actions' },
      ],
    }
  },
  created() {
    this.getEventDataWithSessions(this.$router.currentRoute.params.id)
  },
  setup() {
    const {
      eventData,
      sessionsList,
      getEventDataWithSessions,
    } = getEventWithSessions()

    return {
      eventData,
      sessionsList,
      getEventDataWithSessions,
    }
  },
}
</script>
<style lang="scss">

</style>
