import { ref } from '@vue/composition-api'

export default function startDatePassed() {
  const isDatePassed = ref(false)
  const checkIsDatePassed = dateTime => { isDatePassed.value = new Date(dateTime) < new Date() }

  return {
    isDatePassed,
    checkIsDatePassed,
  }
}
