var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.eventData)?_c('div',[_c('b-card',{attrs:{"header":"Event Details"}},[_c('div',{staticClass:"mb-1"},[_c('b',[_vm._v("Name: ")]),_vm._v(" "+_vm._s(_vm.eventData.activity_name)+" ")]),_c('div',{staticClass:"mb-1"},[_c('b',[_vm._v("Start Datetime: ")]),_vm._v(" "+_vm._s(_vm.eventData.start_time)+" ")]),_c('div',{staticClass:"mb-1"},[_c('b',[_vm._v("End Datetime: ")]),_vm._v(" "+_vm._s(_vm.eventData.end_time)+" ")]),_c('div',{staticClass:"mb-1"},[_c('b',[_vm._v("Presence: ")]),_vm._v(" "+_vm._s(_vm.eventData.presence_type)+" ")]),_c('div',{staticClass:"mb-1"},[_c('b',[_vm._v("Notes: ")]),_vm._v(" "+_vm._s(_vm.eventData.activity_description)+" ")])]),_c('b-card',[_c('b-table',{ref:"table",staticClass:"position-relative",attrs:{"no-provider-sorting":true,"show-empty":"","empty-text":"There is no Sessions for this Event!","items":_vm.sessionsList,"fields":_vm.sessionsTableColumns,"aria-busy":"true","responsive":"","primary-key":"id"},scopedSlots:_vm._u([{key:"cell(session_approval)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.session_approval ? 'Yes' : 'No')+" ")]}},{key:"cell(required_vaccination)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"type":"checkbox","value":1,"unchecked-value":0,"disabled":""},model:{value:(item.required_vaccination),callback:function ($$v) {_vm.$set(item, "required_vaccination", $$v)},expression:"item.required_vaccination"}})]}},{key:"cell(session_description)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.session_description ? item.session_description.slice(0,50) : '')+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'sessions-view', params: { id: item.id} }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Bookings")])],1)],1)]}}],null,false,180050926)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }